// https://material.angular.io/guide/theming
// https://glitch.com/~material-theme-builder
@use '@angular/material' as mat;
@use './theme-overrides' as overrides;

@import './schaeffler-colors.scss';
@import './m3-role-colors.scss';
@import './m3-special-colors.scss';
@import './m3-theme-definition.scss';
@import './m3-fonts-definitions.scss';
// Plus imports for other components in your app.

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$schaeffler-primary: mat.m2-define-palette($mat-schaeffler-primary, 700);
$schaeffler-accent: mat.m2-define-palette($mat-schaeffler-secondary, 50);
$schaeffler-warn: mat.m2-define-palette($mat-schaeffler-warn, 500);

// Create the theme object (a Sass map containing all of the palettes).
$schaeffler-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $schaeffler-primary,
      accent: $schaeffler-accent,
      warn: $schaeffler-warn,
    ),
  )
);

$custom-typography: mat.m2-define-legacy-typography-config(
  $font-family: 'Noto Sans',
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
// @include mat.all-legacy-component-typographies($custom-typography);
// @include mat.legacy-core();
// @include mat.all-legacy-component-themes($schaeffler-theme);

:root {
  //define action colors and fonts that could be instanly used in shared components based on the m3 theme
  @include set-theme-colors($schaeffler-m3-theme-light);
  @include set-theme-special-colors($schaeffler-m3-theme-light);
  @include set-theme-fonts-variables($schaeffler-m3-theme-light);
  --high-emphasis-color: rgba(0, 0, 0, 0.87);
  --medium-emphasis-color: rgba(0, 0, 0, 0.6);
  --low-emphasis-color: rgba(0, 0, 0, 0.38);
}

@include mat.all-component-themes($schaeffler-theme);
$prefix: '' !default;

@include overrides.schaeffler-theme-overrides($schaeffler-theme, $prefix);
