@use '@angular/material' as mat;

@import './schaeffler-colors.scss';

@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

@mixin set-theme-colors($theme) {
  // 1 - m3 theme version
  // Once newer version of angular material will be merged verify following ticket fixed color issues https://github.com/angular/components/issues/29577
  @if (mat.get-theme-version($theme) == 1) {
    $primary: mat.get-theme-color($theme, primary);
    $primary-color-rgba: hexToRGB($primary);

    $secondary-color: mat.get-theme-color($theme, secondary);
    $secondary-color-rgb: hexToRGB($secondary-color);

    $tw-opacity: #{var(--tw-bg-opacity)};

    --primary-color: #{$primary-color-rgba};
    --primary-color-hex: #{$primary};

    --on-primary-color: #{mat.get-theme-color($theme, on-primary)};
    --primary-container-color: #{mat.get-theme-color(
        $theme,
        primary-container
      )};
    --on-primary-container-color: #{mat.get-theme-color(
        $theme,
        on-primary-container
      )};
    --primary-fixed-color: #{mat.get-theme-color($theme, primary-fixed)};
    --primary-fixed-dim-color: #{mat.get-theme-color(
        $theme,
        primary-fixed-dim
      )};
    --on-primary-fixed-color: #{mat.get-theme-color($theme, on-primary-fixed)};
    --on-primary-fixed-variant-color: #{mat.get-theme-color(
        $theme,
        on-primary-fixed-variant
      )};

    --secondary-color: #{$secondary-color-rgb};
    --secondary-color-hex: #{$secondary-color};
    --on-secondary-color: #{mat.get-theme-color($theme, on-secondary)};
    --secondary-container-color: #{mat.get-theme-color(
        $theme,
        secondary-container
      )};
    --on-secondary-container-color: #{mat.get-theme-color(
        $theme,
        on-secondary-container
      )};
    --secondary-fixed-color: #{mat.get-theme-color($theme, secondary-fixed)};
    --secondary-fixed-dim-color: #{mat.get-theme-color(
        $theme,
        secondary-fixed-dim
      )};
    --on-secondary-fixed-color: #{mat.get-theme-color(
        $theme,
        on-secondary-fixed
      )};
    --on-secondary-fixed-variant-color: #{mat.get-theme-color(
        $theme,
        on-secondary-fixed-variant
      )};

    --tertiary-color: #{mat.get-theme-color($theme, tertiary)};
    --on-tertiary-color: #{mat.get-theme-color($theme, on-tertiary)};
    --tertiary-container-color: #{mat.get-theme-color(
        $theme,
        tertiary-container
      )};
    --on-tertiary-container-color: #{mat.get-theme-color(
        $theme,
        on-tertiary-container
      )};
    --tertiary-fixed-color: #{mat.get-theme-color($theme, tertiary-fixed)};
    --tertiary-fixed-dim-color: #{mat.get-theme-color(
        $theme,
        tertiary-fixed-dim
      )};
    --on-tertiary-fixed-color: #{mat.get-theme-color(
        $theme,
        on-tertiary-fixed
      )};
    --on-tertiary-fixed-variant-color: #{mat.get-theme-color(
        $theme,
        on-tertiary-fixed-variant
      )};
    --error-color: #{mat.get-theme-color($theme, error)};
    --on-error-color: #{mat.get-theme-color($theme, on-error)};
    --error-container-color: #{mat.get-theme-color($theme, error-container)};
    --on-error-container-color: #{mat.get-theme-color(
        $theme,
        on-error-container
      )};
    --surface-dim-color: #{mat.get-theme-color($theme, surface-dim)};

    @if mat.get-theme-type($theme) == dark {
      // dark theme issue with color
      --surface-color: #{mat.get-theme-color($theme, neutral, 6)};
    } @else {
      --surface-color: #{mat.get-theme-color($theme, neutral, 98)};
      //--surface-color: #{mat.get-theme-color($theme, surface)};
    }

    --surface-bright-color: #{mat.get-theme-color($theme, surface-bright)};
    --surface-container-lowest-color: #{mat.get-theme-color(
        $theme,
        surface-container-lowest
      )};
    --surface-container-low-color: #{mat.get-theme-color(
        $theme,
        surface-container-low
      )};
    --surface-container-color: #{mat.get-theme-color(
        $theme,
        surface-container
      )};
    --surface-container-high-color: #{mat.get-theme-color(
        $theme,
        surface-container-high
      )};

    @if mat.get-theme-type($theme) == dark {
      // dark theme issue with color
      --surface-container-highest-color: #{mat.get-theme-color(
          $theme,
          neutral,
          24
        )};
    } @else {
      --surface-container-highest-color: #{mat.get-theme-color(
          $theme,
          surface-container-highest
        )};
    }

    --on-surface-color: #{mat.get-theme-color($theme, on-surface)};

    // override requested by UX team
    @if mat.get-theme-type($theme) == dark {
      --on-surface-variant-color: #{mat.get-theme-color($theme, neutral, 70)};
    } @else {
      --on-surface-variant-color: #{mat.get-theme-color($theme, neutral, 40)};
    }

    --outline-color: #{mat.get-theme-color($theme, outline)};
    --outline-variant-color: #{mat.get-theme-color($theme, outline-variant)};
    --inverse-surface-color: #{mat.get-theme-color($theme, inverse-surface)};
    --inverse-on-surface-color: #{mat.get-theme-color(
        $theme,
        inverse-on-surface
      )};
    --inverse-primary-color: #{mat.get-theme-color($theme, inverse-primary)};
    --scrim-color: #{mat.get-theme-color($theme, scrim)};
    --shadow-color: #{mat.get-theme-color($theme, shadow)};

    //typography
    --font-display-large: #{mat.get-theme-typography(
        $theme,
        display-large,
        font
      )};
    --font-display-medium: #{mat.get-theme-typography(
        $theme,
        display-medium,
        font
      )};
    --font-display-small: #{mat.get-theme-typography(
        $theme,
        display-small,
        font
      )};
    --font-headline-large: #{mat.get-theme-typography(
        $theme,
        headline-large,
        font
      )};
    --font-headline-medium: #{mat.get-theme-typography(
        $theme,
        headline-medium,
        font
      )};
    --font-headline-small: #{mat.get-theme-typography(
        $theme,
        headline-small,
        font
      )};
    --font-title-large: #{mat.get-theme-typography($theme, title-large, font)};
    --font-title-medium: #{mat.get-theme-typography(
        $theme,
        title-medium,
        font
      )};
    --font-title-small: #{mat.get-theme-typography($theme, title-small, font)};
    --font-body-large: #{mat.get-theme-typography($theme, body-large, font)};
    --font-body-medium: #{mat.get-theme-typography($theme, body-medium, font)};
    --font-body-small: #{mat.get-theme-typography($theme, body-small, font)};
    --font-label-large: #{mat.get-theme-typography($theme, label-large, font)};
    --font-label-medium: #{mat.get-theme-typography(
        $theme,
        label-medium,
        font
      )};
    --font-label-small: #{mat.get-theme-typography($theme, label-small, font)};

    --label-large-letter-spacing: #{mat.get-theme-typography(
        $theme,
        label-large,
        letter-spacing
      )};
  } @else {
    // m2 possiblity to declare colors for m2 if prefered, however m3 theme can be used to generate colors
    //--primary-color: #{$primary};
  }
}
