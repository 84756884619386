/***************************************************************************************************
* COMPONENTS AND THEMES
*/

/***************************************************************************************************
* UTILITIES
*/

/*
* TailwindCSS, utility-first css framework
* see https://tailwindcss.com/docs
*/
@forward 'tailwindcss/base';
@forward 'tailwindcss/components';
@forward 'tailwindcss/utilities';

/***************************************************************************************************
  * DECLARATIONS
  */
@use 'node_modules/ag-grid-community/styles' as ag;

/*
* Angular Material, material design components
* see https://material.angular.io
*/

@use 'libs/shared/ui/styles/src/lib/scss/material-theme' as m2;

/*
* ag grid components
* see https://www.ag-grid.com/angular-grid
*/
@include ag.grid-styles(
  (
    theme: material,
    material-primary-color: m2.$primary,
    material-accent-color: m2.$info,
    checkbox-checked-color: m2.$primary,
  )
);

/***************************************************************************************************
* OVERRIDES
*/

// custom overrides for native elements
button {
  outline: none !important;
}

// disable focus ripple effect
.mdc-checkbox__ripple {
  @apply hidden;
}

// set theme color for sliders
.mdc-slider__value-indicator {
  background-color: var(--mdc-theme-primary, #00893e) !important;
  opacity: 1 !important;
}

// disable slider ripples
.mat-ripple-element.mat-mdc-slider-focus-ripple,
.mat-ripple-element.mat-mdc-slider-active-ripple,
.mat-ripple-element.mat-mdc-slider-hover-ripple {
  display: none !important;
}

// custom overrides for ag grid
.ag-theme-material {
  --ag-font-family: 'Noto sans';
  --ag-selected-row-background-color: #eee;
  .ag-row {
    border-color: rgba($color: #000000, $alpha: 0.12);
    border-top: none;
    border-width: 0.5px;
  }

  .ag-row-hover::before {
    opacity: 0.15;
  }

  .ag-column-panel {
    border-left: 1px solid ag-param(border-color);
    border-right: 1px solid ag-param(border-color);
  }

  .ag-horizontal-left-spacer {
    overflow: hidden;
  }

  .ag-cell-wrapper > :not(.ag-cell-value):not(.ag-group-value) {
    align-items: center;
    display: flex;
    flex: none;
    height: 30px;
  }

  .line-height-30,
  .ag-right-aligned-cell {
    line-height: 30px !important;
  }

  .ag-side-button-button {
    &:hover {
      color: m2.$primary !important;
    }
  }

  .ag-status-bar {
    @apply border-0 #{!important};
    @apply p-0 #{!important};
    @apply items-center #{!important};
  }

  &.tree-view-table {
    .ag-group-expanded {
      margin-right: 4px !important;
    }

    .ag-group-contracted {
      margin-right: 4px !important;
    }

    .ag-row-level-0 {
      &:not(.ag-row-group) {
        > .ag-cell:first-of-type {
          padding-left: 32px;
        }
      }
    }

    @for $i from 1 to 20 {
      .ag-row-level-#{$i} .ag-row-group-leaf-indent {
        margin-left: 22px !important;
      }
    }
  }

  .ag-floating-bottom {
    font-weight: bold;
  }

  &.embedded-table {
    .ag-row {
      border-left: none;
      border-right: none;
    }
  }
}
