@use '@angular/material' as mat;

@mixin schaeffler-theme-overrides($theme, $prefix) {
  // expansion panel overrides
  mat-accordion.mat-accordion {
    mat-expansion-panel.mat-expansion-panel {
      @apply #{$prefix}shadow-none;
      @apply #{$prefix}border-l;
      @apply #{$prefix}border-r;
      @apply #{$prefix}border-b;
      @apply #{$prefix}border-border;

      &:first-of-type {
        @apply #{$prefix}border-t;
      }

      &.mat-expanded {
        @apply #{$prefix}border-t;

        & + .mat-expansion-panel {
          @apply #{$prefix}border-t;
        }
      }

      .mat-expansion-panel-header {
        @apply #{$prefix}rounded-none;
      }

      .mat-expansion-panel-body {
        @apply #{$prefix}p-0;
      }
    }
  }

  // input overrides
  .mat-form-field-appearance-outline .mat-form-field-outline {
    @apply #{$prefix}bg-surface-legacy;
    border-radius: 5px;
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    @apply #{$prefix}text-medium-emphasis;
  }

  .mat-form-field-prefix {
    @apply #{$prefix}mr-2;
  }

  // input fields
  .mat-mdc-form-field-focus-overlay {
    @apply #{$prefix}bg-transparent #{!important};
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    @apply #{$prefix}text-medium-emphasis;
  }

  .mat-mdc-form-field-icon-suffix {
    @apply #{$prefix}mr-2;
  }

  .mat-form-field-subscript-wrapper {
    @apply #{$prefix}text-[75%];
  }

  .mat-mdc-form-field-hint {
    @apply #{$prefix}text-caption;
    @apply #{$prefix}text-medium-emphasis;
  }

  // button overrides
  .mat-mdc-button-base {
    @apply #{$prefix}text-button;
    @apply #{$prefix}uppercase;

    &.mat-mdc-stroked-button.mat-mdc-button-disabled {
      @apply #{$prefix}border-border;
    }
  }

  .mdc-button__label {
    @apply #{$prefix}text-button;
  }

  .mat-mdc-button-base:not(.mat-mdc-fab):not(.mat-mdc-mini-fab):not(
      .mat-mdc-icon-button
    ) {
    mat-icon {
      @apply #{$prefix}-ml-1.5 #{$prefix}mr-2 #{$prefix}h-[18px] #{$prefix}w-[18px] #{$prefix}text-[18px];
    }
  }

  // tooltip overrides
  mat-tooltip-component .mdc-tooltip {
    @apply #{$prefix}text-caption #{$prefix}whitespace-pre-line #{$prefix}px-4 #{$prefix}py-2;
    //@apply #{$prefix}bg-secondary-900; // - apply style only for outer container
    --mdc-plain-tooltip-container-color: #3c3c3c;
  }

  mat-slide-toggle .mdc-switch__icons {
    @apply #{$prefix}hidden;
  }

  /*
    weird border issue Angular material with Tailwind https://github.com/tailwindlabs/tailwindcss/discussions/9993
    in preflight style of tailwinds sets border-style to solid, other way to fix it, would be to adjust
    preflight, which can case other issues for tailwind
    */
  .mdc-notched-outline__notch {
    border-right: none !important;
  }

  .cdk-global-overlay-wrapper .cdk-overlay-pane {
    @apply #{$prefix}pointer-events-auto;
  }

  .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    @apply #{$prefix}fixed #{$prefix}pointer-events-auto #{!important};
  }

  .cdk-overlay-pane.mat-mdc-tooltip-panel {
    @apply #{$prefix}w-fit;
  }

  .cdk-overlay-pane.mat-datepicker-popup
    mat-month-view
    .mat-calendar-table-header
    .cdk-visually-hidden {
    @apply w-[1px] h-[1px] absolute overflow-hidden;
  }

  .mat-mdc-dialog-container {
    @apply #{$prefix}relative;
  }

  .cdk-overlay-connected-position-bounding-box {
    @apply #{$prefix}fixed #{$prefix}flex #{$prefix}flex-col #{$prefix}w-0 #{$prefix}h-0 #{!important};
  }

  .cdk-overlay-backdrop {
    @apply #{$prefix}top-0 #{$prefix}left-0 #{$prefix}h-full #{$prefix}w-full #{$prefix}fixed #{$prefix}pointer-events-auto;
  }

  .cdk-overlay-container {
    @apply #{$prefix}top-0 #{$prefix}left-0 #{$prefix}h-full #{$prefix}w-full #{$prefix}fixed #{$prefix}pointer-events-none;
  }

  .cdk-overlay-container:empty,
  .cdk-overlay-container:has(.cdk-overlay-connected-position-bounding-box) {
    @apply #{$prefix}pointer-events-none;
  }

  .cdk-global-overlay-wrapper {
    @apply #{$prefix}absolute #{$prefix}top-0 #{$prefix}left-0 #{$prefix}h-full #{$prefix}w-full #{$prefix}flex;
  }

  .cdk-global-overlay-wrapper:has(.cdk-overlay-pane > mat-snack-bar-container) {
    @apply #{$prefix}pointer-events-none;
  }

  .cdk-overlay-container:has(
      .cdk-global-overlay-wrapper > .cdk-overlay-pane > mat-snack-bar-container
    ) {
    @apply #{$prefix}pointer-events-none;
  }

  .mat-mdc-tab-group > .mat-mdc-tab-header .mat-mdc-tab {
    @apply #{$prefix}relative;
  }

  .mdc-tab.mat-mdc-tab-link.mat-mdc-focus-indicator {
    @apply #{$prefix}relative;
  }

  .mat-ripple.mat-mdc-tab-ripple .mat-ripple-element {
    @apply #{$prefix}absolute #{$prefix}top-0 #{$prefix}left-0 #{$prefix}w-full #{$prefix}h-full #{!important};
  }

  mat-button-toggle-group
    mat-button-toggle
    .mat-button-toggle-ripple
    .mat-ripple-element {
    @apply #{$prefix}max-w-full #{$prefix}max-h-full #{$prefix}bg-focus-overlay #{!important};
  }

  .mat-ripple.mat-mdc-checkbox-ripple.mat-mdc-focus-indicator
    .mat-ripple-element {
    @apply #{$prefix}max-w-full #{$prefix}max-h-full #{$prefix}rounded-full #{!important};
  }

  .cdk-overlay-backdrop.cdk-overlay-dark-backdrop {
    @apply #{$prefix}bg-backdrop-overlay;
  }

  .mat-mdc-slider-visual-thumb
    .mat-ripple.mat-mdc-focus-indicator
    .mat-ripple-element {
    @apply #{$prefix}absolute #{$prefix}top-0 #{$prefix}left-0 #{$prefix}max-w-full #{$prefix}max-h-full #{$prefix}rounded-full #{!important};
  }
}

@mixin schaeffler-theme-overrides-m3($theme, $prefix) {
  .mat-mdc-form-field-icon-suffix {
    @apply #{$prefix}mr-2;
  }

  .mat-mdc-slider .mdc-slider__track--inactive {
    @apply #{$prefix}opacity-100 #{!important};
  }

  /*
    weird border issue Angular material with Tailwind https://github.com/tailwindlabs/tailwindcss/discussions/9993
    in preflight style of tailwinds sets border-style to solid, other way to fix it, would be to adjust
    preflight, which can case other issues for tailwind
    */
  .mdc-notched-outline__notch {
    border-right: none !important;
  }
}
